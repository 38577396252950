<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="exit_voucher"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <div>
            <b-row
              style="
                border: 1px solid black;
                border-radius: 18px;
                padding: 10px;
              "
            >
              <!-- Header: Left Content -->

              <b-col
                cols="12"
                md="12"
                style="color: black; font-size: 18px; text-align: center"
              >
                <img
                  src="@/assets/images/logo/logo.png"
                  width="150px"
                  height="auto"
                >
                <div
                  style="color: black; font-size: 40px"
                  class="font-weight-bolder"
                >
                  GROUPES SERVICES RAPIDES
                  <div style="font-size: 28px">
                    Vente tous genre de cléfs brut, machines et accessoires
                  </div>
                </div>

                <b-card-text
                  style="font-size: 20px"
                  class="font-weight-bold mt-1"
                >
                  <div>RUE EL KOUNAITRA 1-dar Chaabene El Fehri</div>
                  <div class="mt-1">
                    Tel: +216 97 607 740 , Email: gsr-cle1@hotmail.fr
                  </div>

                  <div class="mt-1 mb-1">
                    MF : 1082793 F/A/M/000 | C.D 837979P
                  </div>
                </b-card-text>
              </b-col>
            </b-row>
            <div class="mt-1">
              <b-row style="color: black">
                <b-col cols="5">
                  <h3
                    class="font-weight-bolder"
                    style="
                      color: black;
                      font-size: 28px;
                      padding: 10px;
                      text-align: center;
                    "
                  >
                    BON DE SORTIE
                  </h3>
                  <h3
                    class="font-weight-bolder"
                    style="
                      color: black;
                      font-size: 28px;
                      padding: 10px;
                      text-align: center;
                    "
                  >
                    N° {{ exit_voucher.number }} DU
                    <span style="font-size: 20px">
                      {{ format(exit_voucher.date) }}</span>
                  </h3>
                </b-col>
                <b-col
                  v-if="exit_voucher.driver"
                  cols="7"
                  style="
                    border: 1px solid black;
                    border-radius: 18px;
                    padding: 10px;
                    background-color: #eeede9;
                  "
                >
                  <!-- Header: Left Content -->
                  <div style="color: black; font-size: 25px">
                    <h4
                      style="color: black; font-size: 25px"
                      class="mb-1 font-weight-bolder"
                    >
                      {{ exit_voucher.driver.name }}
                    </h4>
                    <div
                      v-if="exit_voucher.driver.cin"
                      class="mb-0 font-weight-bold"
                    >
                      <strong>Cin : </strong> {{ exit_voucher.driver.cin }}
                    </div>
                    <div
                      v-if="exit_voucher.truck"
                      class="mb-0 font-weight-bold"
                    >
                      <strong>Camion : </strong>
                      {{ exit_voucher.truck.serial_number }}
                    </div>
                    <div class="mb-0 font-weight-bold">
                      <strong>Validité :</strong> Du
                      {{ format(exit_voucher.date_start) }} Au
                      {{ format(exit_voucher.date_end) }}
                    </div>
                    <div class="mb-0 font-weight-bold">
                      <strong>Secteurs :</strong>
                      <span
                        v-for="(item, index) in exit_voucher.states"
                        :key="index"
                      >
                        {{ item.name }} /
                      </span>
                    </div>
                  </div>

                  <!-- Header: Right Content -->
                  <!-- Header: Right Content -->
                </b-col>
              </b-row>
            </div>
            <div
              v-if="isLoading === true"
              class="text-center mb-2 mt-2"
            >
              <b-spinner
                variant="primary"
                label="Text Centered"
              />
            </div>
            <!-- Invoice Description: Table -->
            <table class="container-products mt-2">
              <b-table-lite
                responsive
                :items="p"
                :small="true"
                :fields="[
                  'Réf',
                  'Désignation',
                  'Qté',
                  'Prix U.H.T',
                  'Total H.T',
                  'Taux TVA',
                  'Prix U.TTC',
                  'Total TTC',
                ]"
                class="ta font-weight-bold mt-1"
              >
                <template #cell(taskDescription)="data">
                  <b-card-text
                    class="font-weight-bold mb-25"
                    style="font-size: 25px; border: 2px solid black !important"
                  >
                    {{ data.item.taskTitle }}
                  </b-card-text>
                  <b-card-text class="text-nowrap">
                    {{ data.item.taskDescription }}
                  </b-card-text>
                </template>
              </b-table-lite>
            </table>
            <!-- Invoice Description: Total -->
            <div class="page-break">
              <b-row class="card-body">
                <b-col
                  cols="6"
                  md="6"
                  xl="6"
                  sm="12"
                  style="
                    border: 1px solid black;
                    padding: 10px;
                    border-radius: 18px;
                  "
                >
                  <span
                    class="font-weight-bolder mt-1"
                    style="
                      color: black;
                      border-bottom: 1px solid black;
                      font-size: 24px;
                    "
                  >
                    Arreteé le présent bon de sortie a la somme de:
                  </span>
                  <br><br>
                  <span
                    class="font-weight-bold mt-1"
                    style="color: black; font-size: 24px"
                  >
                    {{ lettre }}
                  </span>
                  <!-- <div v-html="order.note" /> -->
                </b-col>
                <b-col cols="1" />
                <b-col
                  cols="5"
                  md="5"
                  xl="5"
                  sm="12"
                  class="p-0"
                  style="
                    border: 1px solid black;
                    padding: 10px;
                    border-radius: 18px;
                  "
                >
                  <table class="border-pricing m-1">
                    <tbody>
                      <tr class="trborder">
                        <th
                          scope="row"
                          style="color: black; font-size: 24px"
                          class="border-pricing"
                        >
                          Total H.Tva :
                        </th>
                        <td
                          style="color: black; font-size: 24px"
                          class="font-weight-bold border-pricing"
                        >
                          {{ exit_voucher.price_ht }}
                        </td>
                      </tr>
                      <tr
                        class="trborder"
                        style="color: black; font-size: 24px"
                      >
                        <th
                          scope="row"
                          class="border-pricing"
                        >
                          Total Tva :
                        </th>
                        <td class="font-weight-bold border-pricing">
                          {{ exit_voucher.tax }}
                        </td>
                      </tr>

                      <tr style="color: black; font-size: 24px">
                        <th
                          scope="row"
                          class="border-pricing"
                        >
                          Total TTC :
                        </th>
                        <td class="font-weight-bold border-pricing">
                          {{ exit_voucher.price_ttc }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row class="card-body text-center">
                <b-col
                  cols="6"
                  md="6"
                  xl="6"
                  sm="12"
                >
                  <h5 style="color: black; font-size: 16px">
                    Signature
                  </h5>
                </b-col>
              </b-row>
            </div>
          </div></b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

import {
  BRow,
  BCol,
  BCard,
  BTableLite,
  BCardText,
  BButton,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { NumberToLetter } from 'convertir-nombre-lettre'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BCardText,
    BButton,
    BSpinner,
  },
  data() {
    return {
      p: [],
      test: '0',
      exit_voucher: [],
      lettre: '',
      isLoading: false,
      id: null,
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getExitVoucher()
    this.getProducts()
  },
  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split('/')
      return `${year.padStart(0, '0')}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0',
      )}`
    },
    async getExitVoucher() {
      const { data } = await axiosIns.get(`/exit-vouchers/details/${this.id}/`)
      this.exit_voucher = data
      const sommeString = this.exit_voucher.price_ttc.toString()
      if (sommeString.includes('.000') === true) {
        this.lettre = `${NumberToLetter(this.exit_voucher.price_ttc)} Dinars `
      } else {
        const [dinar, milim] = this.exit_voucher.price_ttc.split('.')
        this.lettre = `${NumberToLetter(dinar)} Dinars , ${NumberToLetter(
          milim,
        )} `
      }
    },

    async getProducts() {
      this.isLoading = true
      const { data } = await axiosIns.post('/exit-vouchers-products/details/', {
        exit_voucher: this.id,
      })
      for (let i = 0; i < data.length; i += 1) {
        this.addnewitem(
          i + 1,
          data[i].reference,
          data[i].label,
          data[i].quantity,
          data[i].price_ht,
          data[i].total_ht,
          data[i].vat,
          data[i].price_ttc,
          data[i].total_ttc,
        )
      }
      this.isLoading = false
    },

    // eslint-disable-next-line camelcase
    addnewitem(
      nb,
      reference,
      label,
      quantity,
      price_ht,
      total_ht,
      tax,
      price_ttc,
      total_ttc,
    ) {
      this.p.push({
        Réf: reference,
        Désignation: label,
        Qté: quantity,
        'Prix U.H.T': price_ht,
        'Total H.T': total_ht,
        'Taux TVA': tax,
        'Prix U.TTC': price_ttc,
        'Total TTC': total_ttc,
        'N°': nb,
      })
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeQte = 0
    const sommeCBM = 0
    const sommeGW = 0
    const sommeNW = 0

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
      sommeQte,
      sommeCBM,
      sommeGW,
      sommeNW,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
.trborder {
  border-bottom: 0px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #208a9d;
}
.contact {
  background-color: #208a9d;
  padding: 10px;
  border-radius: 20px;
}

@media print {
  @page {
    size: A4;
  }
  .page-break {
    page-break-inside: avoid;
  }

  .ta {
    overflow-x: visible;
    font-size: 18px;
    color: black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #208a9d;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #208a9d;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .container-products{
    min-height: 600px;
    border: 1px solid black;
  }
  table {
    width: 100%;
  }
  * {
    color: black !important;
  }

  .border-pricing {
    text-align: left;
    border: 0px solid black;
  }
}
.container-products{
  min-height: 600px;
  border: 1px solid black;
}
</style>
